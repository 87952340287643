$(function () {

    // Replace default jQuery validate error messages: https://stackoverflow.com/a/2457053/1693918
    $.extend($.validator.messages, {
        required: "Dette felt er p&aring;kr&aelig;vet.",
        email: "Indtast venligst en gyldig e-mail.",
        equalTo: "Indtast venligst samme v&aelig;rdi igen."
    });

    $(document).on('click', '.yamm .dropdown-menu', function (e) {
        e.stopPropagation();
    });
	
	$('.altaddress').change(function() {
		if(this.checked){
            $('#altaddress').removeClass("hidden");
		} else {
            $('#altaddress').addClass("hidden");
		}
    });

    if($('[data-fancybox="gallery-caption"]').length){
	    $('[data-fancybox="gallery-caption"]').fancybox({
            arrows: true,
            buttons: [
                "zoom",
                //"share",
                //"slideShow",
                "fullScreen",
                //"download",
                "thumbs",
                "close"
            ],
        });
    }

    $("#header").on('click', '.navbar-toggle', function (e) {
        $("#navigation").toggleClass("open");
    });

    $("#navigation .yamm-content .toggle-submenu").click(function() {
        $(this).siblings(".list-unstyled").toggleClass("hidden-xs hidden-sm hidden-md");
        $(this).closest(".yamm-col").toggleClass("open");
    });

    //Unfold menu on mobile
    /*function unfoldMobileNav() {
        var doneClass = $("#navigation").hasClass("ready");

        if( $(window).width() < 768 && !doneClass) {
            $("#navigation").addClass("ready");
            var activeparent = $("#navigation .yamm-col").find(".header.active");
            if(activeparent.length){
                activeparent.closest(".yamm-fw").addClass("open");
            }

            var activesub = $("#navigation .yamm-col ul").find("a.active");
            if(activesub.length){
                activesub.closest(".yamm-col").addClass("open");
                activesub.closest(".yamm-fw").addClass("open");
                activesub.closest(".list-unstyled").removeClass("hidden-xs");
            }
        }    
    }*/
    if($('.swiper-container').length){
    	var mySwiper = new Swiper('.swiper-container', {
            loop: true,
            autoplay: {
                delay: 5000
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        });
    }
	
	function getVisible() {    
		var $el = $('footer'),
			scrollTop = $(this).scrollTop(),
			scrollBot = scrollTop + $(this).height(),
			elTop = $el.offset().top,
			elBottom = elTop + $el.outerHeight(),
			visibleTop = elTop < scrollTop ? scrollTop : elTop,
			visibleBottom = elBottom > scrollBot ? scrollBot : elBottom;
			return visibleBottom - visibleTop;
	}

    $(window).resize(function() {
        calculateSticky();
    });

    calculateSticky();
    function calculateSticky() {
        $(".sticky-sidebar").sticky({
            topSpacing: 0,
            bottomSpacing: $("footer").outerHeight() + $("#partners").outerHeight() + $("#footer-bottom").outerHeight()
        });
    }

	$('p a[href*=#]:not([href=#])').click(function() {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: $($(this).attr('href')).offset().top
				}, 500);
				return false;
			}
		}
	});

	/* Åbner / lukker video afspiller */
	$("a.videoLink").click(function (event) {
		event.preventDefault();
		$('#video-modal .modal-content h2').text($(this).attr("data-title"));
		$('#video-modal .modal-content .description').html($(this).attr("data-description"));
		$('#video-modal .modal-content .embed-responsive').html($(this).attr("data-embed"));
		$('#video-modal').modal('show');

		$("#videoContainer .embed-responsive").html($(this).attr("data-embed"));
		$("#videoContainer").removeClass("hidden");
		if($("#video-archive").length != 0) {
			$("#videoContainer .video-info").html("");
			$("#videoContainer .video-info").append("<h2>"+$(this).attr("data-title")+"</h2>");
			$("#videoContainer .video-info").append($(this).attr("data-description"));
		}
	});

    $(".contact-box form").submit(function () {
        $('.contact-box form button').addClass("btn btn-primary disabled").prop('disabled', true);
	});

	$("#video-modal").on('hidden.bs.modal', function (e) {
        $("#video-modal iframe").attr("src", $("#video-modal iframe").attr("src"));
    });

	$(".close-video").click(function (event) {
		event.preventDefault();
		$("#videoContainer").addClass("hidden");
		$("#videoContainer .embed-responsive").html("");
	});
	
	$(".twitter-feed .next").click(function(){
		var active = $('.twitter-feed li.active').next().length > 0 ? $('.twitter-feed li.active').next() : $('.twitter-feed li').first();
		$('.twitter-feed li.active').removeClass('active');
		active.addClass('active');
    });

    $(".twitter-feed .prev").click(function(){
		var active = $('.twitter-feed li.active').prev().length > 0 ? $('.twitter-feed li.active').prev() : $('.twitter-feed li').last();
		$('.twitter-feed li.active').removeClass('active');
		active.addClass('active');
    });
	
	$(".facebook-feed .next").click(function(){
		var active = $('.facebook-feed li.active').next().length > 0 ? $('.facebook-feed li.active').next() : $('.facebook-feed li').first();
		$('.facebook-feed li.active').removeClass('active');
		active.addClass('active');
    });

    $(".facebook-feed .prev").click(function(){
		var active = $('.facebook-feed li.active').prev().length > 0 ? $('.facebook-feed li.active').prev() : $('.facebook-feed li').last();
		$('.facebook-feed li.active').removeClass('active');
		active.addClass('active');
    });
	
	/* Skjuler / viser medarbejderinfo */
	$(".employee-wrapper .employee-image").click(function (event) {
		var employeeId = $(this).closest(".employee-wrapper").data("employee-id");
        openEmployeeModal(employeeId);
	});
	
	/* Medarbejder typeahead */
	$("#q").change(function() {
		$(".eployeeLine").find(".maxInfo").slideUp("fast", function() {});
		$(".eployeeLine").find(".minInfo").slideDown("fast", function() {});
	});
	
    if ($("#q").length != 0) {
        var newEmployeeList = [];

        $.when(
            $.getJSON($(".typeahead-container").data("search"))
        ).done( function(json) {
            newEmployeeList = json;
            $("#q").prop('disabled', false);            

            $('#q').typeahead({
			    minLength: 3,
			    maxItem: 999,
			    highlight: true,
			    order: "asc",
			    dynamic: true,
			    delay: 200,
			    backdrop: {
				    "background-color": "#fff"
			    },
				template: '<div class="employee-image"><img src="{{avatar}}" class="img-responsive" alt="{{name}}" title="{{name}}"><div class="overlay">Se Specialer</div></div>' +
				'<p style="text-transform: capitalize;"><strong>{{name}}</strong></p>' + 
				'<p><span class="employee-title">{{title}}</span><br><em>{{location}}</em></p>' +
				'<p>' + 
					'<span>Tlf.: </span><a href="tel:{{phone}}" title="Ring til {{name}}">{{phone}}</a><br>' +
					'<span>Mobil: </span><a href="tel:{{mobile}}" title="Ring til {{name}}">{{mobile}}</a><br>' +
					'<a href="tel:{{email}}" title="Send en mail til {{email}}">{{email}}</a><br>' +
					'<a href="{{vcard}}">Hent vcard</a>' +
				'</p>' + 
			    '<p>{{department}}</p>',
			    source: {
				    name: {
                        data: newEmployeeList,
                        display: "name",
				    },
				    title: {
					    data: newEmployeeList,
					    display: "title"
					},
					location: {
						data: newEmployeeList,
						display: "location"
					},
				    phone: {
					    data: newEmployeeList,
					    display: "phone"
				    },
				    mobile: {
					    data: newEmployeeList,
					    display: "mobile"
				    },
				    email: {
					    data: newEmployeeList,
					    display: "email"
					},
					vcard: {
						data: newEmployeeList,
						display: "vcard"
					},
				    department: {
					    data: newEmployeeList,
					    display: "department"
					},
					specialties: {
						data: newEmployeeList,
						display: "specialties"
					}
			    },
			    callback: {
                    onClick: function (node, a, obj, e) {
                        var employeeId = JSON.stringify(obj.id);
                        openEmployeeModal(employeeId);
				    }
			    }
		    });
        });
	}

	$("#newsletter-container .mini-tab, #newsletter-container .close-newsletter").click(function(event) {
		if($(this).hasClass("mini-tab")){
			$(".newsletter-signup").animate({
				bottom: "0"
			}, 500, function() {
				var newsletterThisVisit = $.cookie('newsletterThisVisit');
				var newsletterShownTimes = $.cookie('newsletterShownTimes');
				if(newsletterThisVisit != "shown"){
					$.cookie('newsletterThisVisit', 'shown', {path: '/' });
					if(newsletterShownTimes > 0 || $.cookie('newsletterShownTimes') != undefined){
						newsletterShownTimes++;
						$.cookie('newsletterShownTimes', newsletterShownTimes, { expires: 7 });
					}else{
						$.cookie('newsletterShownTimes', "1", { expires: 7, path: '/' });
					}
				}
			});
		}
		if($(this).hasClass("close-newsletter")){
			$(".newsletter-signup").animate({
				bottom: "-650"
			}, 500, function() {});
		}
	});
	
	$("#newsletter-container input.input-validation-error").keypress(function() {
		$(this).css('background-color', '#fff');
		$(this).css('border', '1px solid #bbb');
	});

	$("#createNewAd").validate({
		errorLabelContainer: ".form-errors",
		errorClass: 'alert alert-danger',
		errorElement: 'label',
		rules: {
			adBuySell: "required",
			adName: "required",
			adDescription: "required",
			adSellerName: "required",
			adSellerAddress: "required",
			adSellerZip: {
				required: true,
				digits: true
			},
			adSellerCity: "required",
			adSellerPhone: {
				required: true,
				digits: true,
				minlength: 8
			},
			adSellerEmail: {
				required: true,
				email: true
			}
		},
		messages: {
			adBuySell: "Angiv venligst om annoncen er køb eller salg",
			adName: "Husk at give din annonce et navn",
			adDescription: "Husk at give din annonce en beskrivelse",
			adSellerName: "Husk at indtaste dit navn",
			adSellerAddress: "Husk at indtaste din adresse",
			adSellerZip: "Husk at indtaste dit postnummer",
			adSellerCity: "Husk at indtaste din by",
			adSellerPhone: "Husk at indtaste dit telefonnummer",
			adSellerEmail: "Husk at indtaste din e-mail adresse",
		},
		submitHandler: function(form) {
			form.submit();
		}
	});

	$(".load-more-related").click(function(){
		for(var i = 0; i<3;i++){
            $(".related-news-item.hidden").first().removeClass("hidden");
        }
		if($(".related-news-item.hidden").length == 0){
			$(this).remove();
		}
    });

	$("#hidepopup").click(function(){
		$.cookie('hidePopup', 'true', { expires: 1825, path: '/' });
		$("#newsletter-container .mini-tab").hide();
		$(".newsletter-signup").animate({
			bottom: "-400"
		}, 500, function() {});
	});
});

function openEmployeeModal(employeeId, employeeName) {
    $.ajax({
        url: "/umbraco/surface/EmployeeModal/GetContent",
        data: "employeeId=" + employeeId,
        cache: false,
        type: "GET",
        success: function(response) {
            $('#employeeModal .modal-content').html(response);
            $('#employeeModal').modal('show');
        },
        error: function(xhr) {
        }
    });
}

function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();

    var elemTop = $(elem).offset().top;
    var elemBottom = elemTop + $(elem).height() - 200;

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

$(function() {
    $('#employee-list-wrapper .employee-wrapper').each(function () {
        if (isScrolledIntoView(this) === true) {
            $(this).removeClass('invisible').addClass('animated fadeInUp');
            var image = $(this).find("img");
            image.attr('src',image.data("url"));
        }
    });
});

$(window).scroll(function () {
    $('#employee-list-wrapper .employee-wrapper').each(function () {
        if (isScrolledIntoView(this) === true && !$(this).hasClass("animated")) {
            $(this).removeClass('invisible').addClass('animated fadeInUp');
            var image = $(this).find("img");
            image.attr('src',image.data("url"));
        }
    });

});

function SignupNewsletter() {
	$('#subForm').submit(function (e) {
		e.preventDefault();
		$.getJSON(
		this.action + "?callback=?",
		$(this).serialize(),
		function (data) {
			if(data.Status === 400){
				alert("Error: " + data.Message);
			}else{
				$.cookie('newsletterSubscribed', 'true', { expires: 1825, path: '/' });
				$("#newsletter-form-container form").html("<strong>Tak for din tilmelding til vores nyhedsbrev.</strong>");
				$(".mini-tab").hide();
				setTimeout(function(){
					$(".newsletter-signup").animate({
						bottom: "-400"
					}, 500, function() {});
				}, 2000);
			}
		});
	});
}